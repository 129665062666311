import * as Yup from "yup";
import { EMAIL_REQUIRED, INVALID_EMAIL, PASSWORD_REQUIRED } from "../../constants/messages";

/**
 * Email validator using Yup.
 * 
 * @constant
 * @name emailValidator
 * @type {Yup.StringSchema<string>}
 */
export const emailValidator = Yup.string().email(INVALID_EMAIL).matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, INVALID_EMAIL).transform((value: string) => value.trim()).required(EMAIL_REQUIRED)

export const LoginSchema = Yup.object({
    email: emailValidator,
    password: Yup.string().required(PASSWORD_REQUIRED),
});