import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EVENT_LOGIN_REDIRECT } from './constants';
import storage from './helpers/storage';


const RedirectLogin: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.addEventListener(EVENT_LOGIN_REDIRECT, () => {
            navigate("/login", {replace: true});
        });
        const token: string | null = storage.getToken();
        if (token === null && !['/forgot-password', '/reset-password', '/setup-password'].includes(location.pathname)) {
            navigate("/login", { replace: true });
        }
    }, []);

    return null; // This component doesn't render anything
};

export default RedirectLogin;
