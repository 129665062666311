import React from 'react'
import { Spinner } from 'react-bootstrap'

const LoaderComponent: React.FC<{isLoading: boolean}> = ({ isLoading }: any) => {
    return (
        <div className={`align-items-center bg-black bg-opacity-50 d-flex h-100 justify-content-center position-fixed start-0 top-0 w-100 z-3 ${isLoading ? 'd-block' : 'd-none'}`}>
            <Spinner animation="border" variant="light" role="output">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default LoaderComponent