import React, { useEffect, useState } from 'react';
import './passwordStrength.scss'
import AppTooltip from './tooltip';

interface IPasswordStrength {
    password: string;
}

const PasswordStrength: React.FC<IPasswordStrength> = ({ password }) => {
    const [strength, setStrength] = useState<string | undefined>();

    useEffect(() => {
        let strengthVal = evaluatePasswordStrength();
        setStrength(strengthVal);
    }, [password])

    const evaluatePasswordStrength = () => {
        let score = 0;

        if (!password) return '';
        
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasTwoDigits = /\d.*\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\[\]{};':"\\|,.<>\/?`~\-]/.test(password);

        // Check password length
        if (password.length > 8) score += 1;
        // Contains lowercase
        if (/[a-z]/.test(password) && hasUppercase) score += 1;
        // Contains uppercase
        if (/[A-Z]/.test(password) && hasLowercase) score += 1;
        // Contains numbers
        if (/\d/.test(password)) score += 1;
        // Contains special characters
        if (/[^A-Za-z0-9]/.test(password)) score += 1;
        // when there is no digit
        if(!hasTwoDigits) score -= 1;
        // when special chars are not there
        if(!hasSpecialChar) score -= 1;

        switch (score) {
            case 0:
            case 1:
            case 2:
                return "week";
            case 3:
                return "medium";
            case 4:
            case 5:
                return "strong";
            default:
                return "week";
        }
    }

    const passwordString = () => {
        return (
            <div><strong>Password Strength</strong>: {strength}</div>
        )
    }

    return (
        <React.Fragment>
                <div className="position-absolute strength-bar">
                    <AppTooltip title={passwordString()} placement={"bottom"}>
                        <div className={`strength-meter strength-${strength}`}></div>
                    </AppTooltip>
                </div>
        </React.Fragment>
    )
}

export default PasswordStrength