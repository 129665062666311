import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom';
// import PageNotFoundComponent from "../pages/page-not-found";
import PageNoAccessComponent from "../pages/no-access-page";

const VerifyAccount = lazy(() =>
    import('../pages/verify-account')
);
const MainContainer = lazy(() =>
    import('../components/layout/main-container')
);
const HomeComponent = lazy(() =>
    import('../pages/home')
);
const ProfileComponent = lazy(() =>
    import('../pages/profile')
);
const ChangePasswordComponent = lazy(() =>
    import('../pages/change-password')
);
const UsersComponent = lazy(() =>
    import('../pages/users')
);
const AddUserComponent = lazy(() =>
    import('../pages/users/add')
);
const DevicesComponent = lazy(() =>
    import('../pages/devices')
);
const AddDeviceComponent = lazy(() =>
    import('../pages/devices/add')
);
const FairsComponent = lazy(() =>
    import('../pages/fairs')
);
const FairDetailsComponent = lazy(() =>
    import('../pages/fairs/details')
);
const AddFairComponent = lazy(() =>
    import('../pages/fairs/add')
);
const PaymentsComponent = lazy(() =>
    import('../pages/payments')
);
const OrdersComponent = lazy(() =>
    import('../pages/orders')
);
const ReportsComponent = lazy(() =>
    import('../pages/reports')
);
const StudentsComponent = lazy(() =>
    import('../pages/students')
);
const InventoryComponent = lazy(() =>
    import('../pages/inventory')
);
const FairInventoryComponent = lazy(() =>
    import('../pages/fair-inventory')
);
const SettingsComponent = lazy(() =>
    import('../pages/settings')
);
const RolesComponent = lazy(() =>
    import('../pages/roles')
);
const AddRoleComponent = lazy(() =>
    import('../pages/roles/add')
);


const protectRoutes: RouteObject[] = [
    {
        path: '/',
        element: <MainContainer />,
        children: [
            {
                path: 'dashboard',
                element: <HomeComponent />,
            },
            {
                path: 'profile',
                element: <ProfileComponent />
            },
            {
                path: 'change-password',
                element: <ChangePasswordComponent />
            },
            {
                path: 'users',
                element: <UsersComponent/>,
            },
            {
                path: 'add-user',
                element: <AddUserComponent title={"Add New User"}/>,
            },
            {
                path: 'edit-user/:id',
                element: <AddUserComponent title={"Edit User"}/>
            },
            {
                path: 'devices',
                element: <DevicesComponent />
            },
            {
                path: 'add-device',
                element: <AddDeviceComponent title={"Add New Device"}/>
            },
            {
                path: 'edit-device/:id',
                element: <AddDeviceComponent title={"Edit Device"}/>
            },
            {
                path: 'fairs',
                element: <FairsComponent />
            },
            {
                path: 'fair-details/:id',
                element: <FairDetailsComponent />
            },
            // {
            //     path: 'add-fair',
            //     element: <AddFairComponent title={"Add New Fair"}/>
            // },
            {
                path: 'edit-fair/:id',
                element: <AddFairComponent title={"Edit Fair Details"}/>
            },
            {
                path: 'payments',
                element: <PaymentsComponent />
            },
            {
                path: 'orders',
                element: <OrdersComponent />
            },
            {
                path: 'reports',
                element: <ReportsComponent />
            },
            {
                path: 'students',
                element: <StudentsComponent />
            },
            {
                path: 'fair-inventory',
                element: <FairInventoryComponent />
            },
            {
                path: 'inventory',
                element: <InventoryComponent />
            },
            {
                path: 'roles',
                element: <RolesComponent />
            },
            {
                path: 'add-role',
                element: <AddRoleComponent title='Add New Role'/>
            },
            {
                path: 'edit-role/:id',
                element: <AddRoleComponent title='Edit Role'/>
            },
            {
                path: 'settings',
                element: <SettingsComponent />
            },
            {
                path: '*',
                element: <PageNoAccessComponent />
            }
        ]
    }, {
        path: 'verify-account',
        element: <VerifyAccount />,
    }
];

export default protectRoutes;