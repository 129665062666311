import { jwtDecode } from "jwt-decode";
import dayjs, { Dayjs } from 'dayjs';
import { User } from "../store/reducers/auth";
import storage from "./storage";
import moment from "moment";
import ability from "../role-manager/ability";

import { DATE_FORMAT } from "../constants";


/**
 * Retrieves the decoded user information from the JWT token.
 * @returns {User|null} The decoded user information, or null if no token is found.
 */
export const getDecodedToken = (): User|null => {
    const token: string|null = storage.getToken();
    if(!token) return null;
    const decoded: any = jwtDecode(token);
    return (decoded as any).data as User;
}

/**
 * Checks if the JWT token is about to expire within the next 2 minutes.
 * @returns {boolean} True if the token is about to expire, otherwise false.
 */
export const isTokenAboutToExpire = (): boolean => {
    const token: string|null = storage.getToken();
    if(!token) return false;
    const decoded: any = jwtDecode(token);
    const expirationTime = decoded.exp * 1000; // Convert expiration time to milliseconds
        
    const dateNow = moment().add(2, "minute").valueOf();
    return expirationTime < dateNow;
}

export const maskPhoneNumber = (phoneNumber: any = "") => {
    if (!phoneNumber) return '-';

    const [countryCode, phone] = phoneNumber.split("-");
    if(phone) {
        const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
    
        if (match) {
            return `${countryCode} (${match[1]}) ${match[2]}-${match[3]}`;
        }
    }

    return phoneNumber;
}; 

export const formattedDate = (date: string|null, utc: boolean = false) => {
    if(!date) return "";
    if(utc) {
        return moment.utc(date).format("MM/DD/YYYY");
    }
    return moment(date).format("MM/DD/YYYY");
}

export const formattedDateTime = (date: string|null) => {
    if(!date) return "";
    return moment(date).format("MM/DD/YYYY hh:mm A");
}

// Check the file extension & type
export const isXLSXFileType = (file: File) => {
    const isXLSXFile = file?.name.endsWith('.xlsx');
    const isXLSXMIME = file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    return (isXLSXFile && isXLSXMIME);
}

export const canRead = (module: string) =>{
    return ability.can("read_only", module);
}

export const canEdit = (module: string) =>{
    return ability.can("edit", module);
}

// Formats a Dayjs object into a specified format, or returns a string date as is.
export const dateToFormattedString = (date: Dayjs | string): string => {
    if (dayjs.isDayjs(date)) {
        return date.isValid() ? date.format(DATE_FORMAT) : '';
    }
    // If date is a string, return it unchanged
    return date as string;
};