import React, { ButtonHTMLAttributes } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Button, ButtonProps } from 'react-bootstrap';

// Define the props interface
interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonProps {
    loading?: boolean;
    children?: React.ReactNode|string|null;
}

const CustomButton: React.FC<CustomButtonProps> = ({ loading, children, ...props }) => {
    return (
        <Button className='d-flex align-items-center gap-1 mw-85 text-white' {...props} disabled={loading||props.disabled}>
            {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: "#FFF" }} spin />} />}
            <div className='ms-1 w-100 text-center'>{children}</div>
        </Button>
    )
}

export default CustomButton;