import React, { FC, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FaRegEye, FaRegEyeSlash, FaInfoCircle } from "react-icons/fa";
import ListGroup from 'react-bootstrap/ListGroup';
import { Button } from 'react-bootstrap';
import PasswordStrength from './passwordStrength';
import AppTooltip from './tooltip';

interface IInputPassword {
    controlId: string;
    label: string;
    placeholder: string;
    handleChange: any;
    handleBlur: any;
    errorsField: any;
    touched: any;
    value: string;
    isPasswordHintVisible?: boolean;
    showStrength?: boolean;
}

/**
 * Input Type Password Reusable Component
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; isPasswordHintVisible: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, isPasswordHintVisible
}
 * @returns {*}
 */
const InputPassword: FC<IInputPassword> = ({
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, isPasswordHintVisible, showStrength
}) => {
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const renderTooltip = () => (
        <ListGroup as="ul" className="text-start tooltipDottedList fs-12 mb-1">
            <ListGroup.Item as="li" className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white">Must contain 1 Capital letter.</ListGroup.Item>
            <ListGroup.Item as="li" className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white">Must contain 1 lower case letter.</ListGroup.Item>
            <ListGroup.Item as="li" className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white">Must contain at least 2 numbers.</ListGroup.Item>
            <ListGroup.Item as="li" className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white">Must contain at least 1 of the following special characters !@#$%^&*()[]{}-_=+~.\/</ListGroup.Item>
            <ListGroup.Item as="li" className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white">Must be a minimum of 8 characters.</ListGroup.Item>
            <ListGroup.Item as="li" className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white">No spaces.</ListGroup.Item>
            <ListGroup.Item as="li" className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white">Must be different than previous passwords.</ListGroup.Item>
        </ListGroup>
    );

    return (
        <Form.Group className="mb-3 pb-1" controlId={controlId}>
            <Form.Label className="mb-1 fs-14">{label}
                {isPasswordHintVisible ?
                    <AppTooltip
                        placement="top"
                        title={renderTooltip()}
                    >
                        <Button className="align-baseline d-inline-block infoIcon ms-1 p-0" variant="link">
                            <FaInfoCircle />
                        </Button>
                    </AppTooltip> : ''}
            </Form.Label>
            <div className="position-relative form-icon-active">
                <Form.Control value={value} type={passwordType} placeholder={placeholder} onBlur={handleBlur} onChange={handleChange} isInvalid={!!errorsField && touched} />
                {showStrength ? <PasswordStrength password={value} /> : ""}
                <Form.Control.Feedback type="invalid">
                    {touched ? errorsField : ''}
                </Form.Control.Feedback>
                <Button variant='link' onClick={togglePassword} className="cursor-pointer position-absolute toggleEyeIcons p-0">
                    {passwordType === "password" ? <span className="eyeOff"><FaRegEyeSlash /></span> : <span className="eyeOn"><FaRegEye /></span>}
                </Button>
            </div>
        </Form.Group>
    );
}

export default InputPassword