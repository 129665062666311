import { AbilityBuilder, createMongoAbility, defineAbility } from '@casl/ability';


export const buildAbilitiesForSuperAdmin = () => {
  const { can, rules } = new AbilityBuilder(createMongoAbility);
  can('manage', 'all');
  return rules
}
export const buildAbilities = (permissions: any[]) => {
  const { can, rules } = new AbilityBuilder(createMongoAbility);

  permissions.forEach(permission => {
    if (permission.read_only) {
      can('read_only', permission.name);
    }
    if (permission.edit) {
      can('edit', permission.name);
    }
  });

  return rules;
};

export default defineAbility((can: any, cannot: any) => {
  //default set all access 
  // can('manage', 'all');
});