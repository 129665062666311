
import Axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosResponse } from 'axios';
import storage from '../helpers/storage';
import { EVENT_LOGIN_REDIRECT } from '../constants';


/**
 * Token Interceptor
 * @param {*} config
 * @returns {*}
 */
const authRequestInterceptor = (config: any) => {
    const token = storage.getToken(); // Make sure `storage.getToken()` is implemented correctly.
    if (token) {
        config.headers = config.headers || {};
        (config.headers as AxiosHeaders)['Authorization'] = `Bearer ${token}`;
    }
    config.headers = config.headers || {};
    (config.headers as AxiosHeaders)['Accept'] = 'application/json';
    return config;
}


const httpClient: AxiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
httpClient.interceptors.request.use(authRequestInterceptor);

// custom Event to listen and move to login
const myEvent = new Event(EVENT_LOGIN_REDIRECT);

// Add a response interceptor
httpClient.interceptors.response.use(
    (response: AxiosResponse) => {
        // You can modify the response here (e.g., data transformation)
        return response.data;
    },
    (error: AxiosError) => {
        /**
         * Unauthorized - 401
         * Logout the user
         */
        if (error.response?.status === 401) {
            storage.clearToken();
            document.dispatchEvent(myEvent)
        }
        
        /**
         * Invalid body error code.
         */
        if(error.response?.status === 400 && (error.response?.data as any)?.message) {
            try {
                const msgType = JSON.parse((error.response?.data as any)?.message || "") ?? "";
                if(typeof msgType === 'object') {
                    (error.response?.data as any).message = msgType;
                    (error.response?.data as any).invalid_body = true;
                }
            } catch (e) {}
        }
        // You can handle errors globally here
        return Promise.reject(error.response);
    }
);


export default httpClient;
