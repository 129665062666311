import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TokenInfo } from '../../types';
import { jwtDecode } from "jwt-decode";

export interface User {
  id: number,
  first_name: string,
  last_name: string,
  full_name: string,
  email: string,
  role: {
    id: number,
    name: string,
    role: string
  },
  email_verified: boolean,
  status: boolean,
  accessToken: string
}

export interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  manualRefresh: boolean;
  title: string;
  permission: any[];
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  manualRefresh: false,
  title: "Dashboard",
  permission: []
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<TokenInfo>) => {
      state.isAuthenticated = true;
      const decoded = jwtDecode(action.payload.access.token);
      state.user = (decoded as any).data as User;
      state.user.accessToken = action.payload.access.token;
      state.manualRefresh = false;
    },
    loader: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    logout: (state: AuthState) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.manualRefresh = false;
      state.title = "Login";
      state.permission = [];
    },
    manualRefreshToken: (state: AuthState, action: PayloadAction<boolean>) => {
      state.manualRefresh = action.payload;
    },
    setTitle: (state: AuthState, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setPermission: (state: AuthState, action: PayloadAction<any[]>) => {
      state.permission = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, loader, logout, manualRefreshToken, setTitle, setPermission } = authSlice.actions;

export default authSlice.reducer