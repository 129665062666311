import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import { AxiosResponse } from 'axios';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './routes';
import storage from './helpers/storage';
import { refreshToken } from './services/auth.service';
import { RootState } from './store';
import { User, loader, login, manualRefreshToken, setPermission } from './store/reducers/auth';
import { TokenInfo } from './types';
import { getDecodedToken, isTokenAboutToExpire } from './helpers';
import RedirectLogin from './redirectLogin';
import LoaderComponent from './components/common/loader';
import { AbilityContext } from './role-manager/can';
import ability, { buildAbilities, buildAbilitiesForSuperAdmin } from './role-manager/ability';
import { ROLES } from './constants';


const App: React.FC = () => {
    const dispatch = useDispatch();
    const { loading, manualRefresh, title } = useSelector((state: RootState) => state.auth);

    const getRefreshToken = () => {
        const token = storage.getRefreshToken() as string;
        const formData: { refreshToken: string } = {
            refreshToken: token
        };
        dispatch(loader(true));
        refreshToken(formData).then((res: any) => {
            dispatch(loader(false));
            const tokenInfo = res.data.token as TokenInfo;
            dispatch(login(tokenInfo));
            storage.setToken(tokenInfo.access.token);
            storage.setRefreshToken(tokenInfo.refresh.token);

            dispatch(setPermission(res.data.permission));
            const user = getDecodedToken() as User;
            if(user.role.role === ROLES.SUPER_ADMIN){
                ability.update(buildAbilitiesForSuperAdmin())
            }else{
                ability.update(buildAbilities(res.data.permission))
            }

        }).catch((error: AxiosResponse) => {
            dispatch(loader(false));
        });
    };

    /**
     * when a manual refresh token is triggered.
     * It calls the refresh token api.
     */
    useEffect(() => {
        if (manualRefresh) {
            getRefreshToken();
            dispatch(manualRefreshToken(false))
        }
    }, [manualRefresh])

    /**
     * Set page title
     */
    useEffect(() => {
        if (title) {
            document.title = "POS Admin - " + title;
        }
    }, [title])

    useEffect(() => {
        /**
         * On reload decode and store token in redux state
         */
        const token: string | null = storage.getToken();
        if (token !== null) {
            const tokenInfo = { access: { token } } as TokenInfo;
            dispatch(login(tokenInfo));
            getRefreshToken();
        }

        /**
         * On reload check if token is about to expire
         * Refresh the token.
         */
        const tokenExpireInTwoMinutes: boolean = isTokenAboutToExpire();
        if (token !== null && tokenExpireInTwoMinutes) {
            getRefreshToken();
        }
    }, []);

    return (
        <React.Suspense fallback={<LoaderComponent isLoading={true} />}>
            <AbilityContext.Provider value={ability}>
            <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#FF6445',
                            fontFamily: 'SourceSansProRegular'
                        },
                        components: {
                            Switch: {
                                handleBg: '#FFFFFF',
                                trackMinWidth: 30,
                                handleSize: 9,
                                trackHeight: 16,
                                trackPadding: 3.5
                            }
                        },
                    }}>
                    <RedirectLogin />
                    <AppRoutes />
                </ConfigProvider>
                {loading && <LoaderComponent isLoading={true} />}
                <ToastContainer position="bottom-right" stacked autoClose={5000} />
            </BrowserRouter>
            </AbilityContext.Provider>
        </React.Suspense>
    );
}

export default App;
