import { MAX_FILE_SIZE, PASSWORD_MIN_CHAR } from ".";

export const EMAIL_REQUIRED = "Email address is required.";
export const INVALID_EMAIL = "Invalid email address";
export const REQUIRED = "Required";
export const PASSWORD_REQUIRED = "Password is required";
export const PASSWORD_STRENGTH = "Password doesn't match the requirement.";
export const PASSWORD_MUST_MATCH = "Confirm Password must match with the password";
export const MIN_CHAR_LENGTH = `Password must be at least ${PASSWORD_MIN_CHAR} characters long.`;




/**
 * Profile
 */
export const CONFIRM_PASSWORD_REQUIRED = "Confirm password is required";

/**
 * Verify Account
 */
export const SECRET_CODE_REQUIRED: string = "Secret code is required.";
export const SECRET_CODE_NUMBER: string = "Secret code must be only digits";
export const SECRET_CODE_LENGTH: number = 4;
export const SECRET_CODE_LENGTH_MESSAGE: string = `Please enter ${SECRET_CODE_LENGTH} digit Secret code.`;

/**
 * Users
 */
export const USER_NAME_MIN = 2;
export const USER_NAME_MAX = 20;
export const FIRST_NAME_REQUIRED = "First Name is required.";
export const FIRST_NAME_MIN_MSG = `First name must be at least ${USER_NAME_MIN} characters`;
export const FIRST_NAME_MAX_MSG = `First name must be at most ${USER_NAME_MAX} characters`;
export const LAST_NAME_REQUIRED = "Last Name is required.";
export const LAST_NAME_MIN_MSG = `Last name must be at least ${USER_NAME_MIN} characters`;
export const LAST_NAME_MAX_MSG = `Last name must be at most ${USER_NAME_MAX} characters`;
export const ROLE_IS_REQUIRED = "Role is required";
export const INVALID_PHONE = "Invalid mobile number.";
export const PHONE_REQUIRED = "Phone number is required.";
export const CODE_REQUIRED = "Country code is required.";

/**
 * Device
 */
export const DEVICE_NAME_MIN = 2;
export const DEVICE_ID_NAME_MAX = 30;
export const DEVICE_NAME_MAX = 20;
export const DEVICE_ID_REQUIRED: string = "Device ID is required.";
export const DEVICE_ID_NAME_MIN_MSG = `Device ID must be at least ${DEVICE_NAME_MIN} characters.`;
export const DEVICE_ID_NAME_MAX_MSG = `Device ID must be at most ${DEVICE_ID_NAME_MAX} characters.`;
export const DEVICE_NAME_REQUIRED: string = "Name is required.";
export const DEVICE_NAME_MIN_MSG = `Device name must be at least ${DEVICE_NAME_MIN} characters.`;
export const DEVICE_NAME_MAX_MSG = `Device name must be at most ${DEVICE_NAME_MAX} characters.`;
export const DEVICE_PURCHASE_DATE_REQUIRED: string = "Purchase Date is required.";
export const DEVICE_WARRANTY_DATE_REQUIRED: string = "Warranty Date is required.";
export const DEVICE_WARRANTY_DATE_MIN: string = "Warranty Date should not be less than purchase date.";


/**
 * Fair
 */
export const FAIR_NAME_REQUIRED: string = "Name is required.";
export const FAIR_NAME_MIN = 2;
export const FAIR_NAME_MAX = 30;
export const FAIR_NAME_MIN_MSG = `Name must be at least ${FAIR_NAME_MIN} characters.`;
export const FAIR_NAME_MAX_MSG = `Name must be at most ${FAIR_NAME_MAX} characters.`;
export const FAIR_ADMIN_REQUIRED = `Please select a Admin.`;
export const FAIR_ADDRESS_REQUIRED = `Address is required.`;
export const FAIR_ADDRESS_MIN = 2;
export const FAIR_ADDRESS_MAX = 100;
export const FAIR_ADDRESS_MIN_MSG = `Name must be at least ${FAIR_ADDRESS_MIN} characters.`;
export const FAIR_ADDRESS_MAX_MSG = `Name must be at most ${FAIR_ADDRESS_MAX} characters.`;
export const FAIR_START_DATE_REQUIRED = `Start Date is required.`;
export const FAIR_END_DATE_REQUIRED = `End Date is required.`;
export const FAIR_END_DATE_MIN: string = "End Date should not be less than start date.";
export const FAIR_DEVICE_ID_REQUIRED: string = "Please select at least one Device.";
export const FAIR_SALES_TAX_RATE_REQUIRED: string = "Sales Tax Rate is required.";
export const FAIR_SALES_TAX_RATE_NUMBER: string = "Sales Tax Rate must be a number.";
export const FAIR_SALES_TAX_RATE_MIN = 0;
export const FAIR_SALES_TAX_RATE_MAX = 100;
export const FAIR_SALES_TAX_RATE_MIN_MAX_MSG = `Sales Tax Rate must be in between ${FAIR_SALES_TAX_RATE_MIN} to ${FAIR_SALES_TAX_RATE_MAX}.`;
export const FAIR_FILE_TYPE = "Only Xlsx file is allowed.";
export const FAIR_FILE_SIZE: string = `File exceed the maximum size of ${MAX_FILE_SIZE / (1024 * 1024)} MB.`;

/**
 * Roles
 */
export const ROLE_DESCRIPTION_MIN = 2;
export const ROLE_DESCRIPTION_MAX = 30;
export const ROLE_REQUIRED: string = "Role Name is required.";
export const ROLE_DESCRIPTION_REQUIRED: string = "Role Description is required.";
export const ROLE_DESCRIPTION_NAME_MIN_MSG = `Role ID must be at least ${ROLE_DESCRIPTION_MIN} characters.`;
export const ROLE_DESCRIPTION_NAME_MAX_MSG = `Role ID must be at most ${ROLE_DESCRIPTION_MAX} characters.`;

/**
 * Fair Inventory
 */
export const FAIR_INVENTORY_QUANTITY_REQUIRED: string = "Quantity must be a number.";
export const FAIR_INVENTORY_QUANTITY_NUMBER: string = "Quantity must be a number.";
export const FAIR_INVENTORY_QUANTITY_MIN = 0;
export const FAIR_INVENTORY_QUANTITY_MIN_MSG = `Quantity must be in greater then ${FAIR_INVENTORY_QUANTITY_MIN}.`;

/**
 * Reports
 */
export const REPORT_TYPE_REQUIRED: string = "Report type is required.";
export const REPORT_SCHOOL_ID_REQUIRED: string = "School Identifier is required.";
export const REPORT_START_DATE_REQUIRED: string = "Start Date is required.";
export const REPORT_END_DATE_REQUIRED: string = "End Date is required.";
export const REPORT_END_DATE_MIN: string = "End Date should not be less than Start Date.";