const useCookie = () => {
    // Function to get a cookie by name
    const getCookie = (name: string) => {
        try {
            const nameEQ = name + "=";
            const ca: any = document.cookie.split(';');
            for (const element of ca) {
                let c = element;
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) {
                    const val = atob(c.substring(nameEQ.length, c.length));
                    return JSON.parse(val);
                }
            }
        } catch (error) {}
        return null;
    };

    // Function to set a cookie
    const setCookieValue = async (name: string, value: any) => {
        let expires = "";
        const days = 30;
        const cookieValue = JSON.stringify(value)
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (btoa(cookieValue) || "") + expires;
    };

    // Function to delete a cookie by name
    const removeCookie = async (name: string) => {
        document.cookie = name + '=; Max-Age=-99999999;';
    };

    return { getCookie, setCookieValue, removeCookie };
};

export default useCookie;
