import { FORGOT_PASSWORD, LOGIN, REFRESH_TOKENS_API, RESET_PASSWORD, SETUP_PASSWORD } from '../constants/api-path';
import httpClient from './interceptor';

/**
 * @param {email: string, password:string} formData
 * @returns Promise<any>
 */
export const loginEmailPassword = (formData: any): Promise<any> => {
    return httpClient.post(LOGIN, formData);
};

/**
 * @param {email: string} formData
 * @returns Promise<any>
 */
export const forgotPassword = (formData: any): Promise<any> => {
    return httpClient.post(FORGOT_PASSWORD, formData);
};

/**
 * @param {token: string, password: string } formData
 * @returns Promise<any>
 */
export const resetPassword = (formData: any): Promise<any> => {
    return httpClient.post(RESET_PASSWORD, formData);
};

/**
 * @param {token: string, password: string } formData
 * @returns Promise<any>
 */
export const setupPassword = (formData: any): Promise<any> => {
    return httpClient.post(SETUP_PASSWORD, formData);
};

/**
 * Validates the provided formData object.
 * 
 * @param {{refreshToken: string}} formData - An object containing the refresh token.
 * @returns {Promise<any>} - A Promise resolving to the validation result.
 */
export const refreshToken = (formData: {refreshToken: string}): Promise<any> => {
    return httpClient.post(REFRESH_TOKENS_API, formData);
};