import { SelectOptions } from "../types";

export const PASSWORD_MIN_CHAR: number = 8;

export const REFRESH_TOKEN: string = "refresh_token";
export const REMEMBER_ME: string = btoa("REMEMBER_ME");

export const DASHBOARD_PATH: string = "/dashboard";
export const VERIFY_ACCOUNT_PATH: string = "/verify-account";

export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~\-])[A-Za-z\d!@#$%^&*()_+[\]{};':"\\|,.<>/?`~\-]{8,}$/;
/**
 * Event
 */
export const EVENT_LOGIN_REDIRECT = "moveToLoginEvent";


export const RecordsPerPage: SelectOptions[] = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 }
];

/**
 * Status options
 */
export const STATUS_OPTIONS: SelectOptions[] = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
];

/**
 * Payment Status Option
 */
export const PAYMENT_STATUS: SelectOptions[] = [
  { label: 'Pending', value: 'pending' },
  { label: 'Completed', value: 'completed' },
  { label: 'Failed', value: 'failed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Under Review', value: 'under_review' }
]

/**
 * Admin types
 */
export const ROLES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
}

/**
 * Country calling codes
 */
export const COUNTRY_CALLING_CODES = ["+1"];


export const DATE_FORMAT: string = "MM/DD/YYYY";

export const MAX_FILE_SIZE: number = 5 * 1024 * 1024; // 5MB

export const ALWAYS_ALLOWED_PROTECTED_ROUTE = ["profile", "change-password", "settings", "*"];

export const MODULES_WITH_PATH: any[] = [
  {
    menuName: "Dashboard",
    read_only: ["dashboard"],
    edit: ["dashboard"]
  },
  {
    menuName: "Users",
    read_only: ["users"],
    edit: ["add-user", "edit-user", "users"]
  },
  {
    menuName: "Devices",
    read_only: ["devices"],
    edit: ["add-device", "edit-device", "devices"]
  },
  {
    menuName: "Fairs",
    read_only: ["fairs", "fair-details"],
    edit: ["add-fair", "edit-fair", "fairs"]
  },
  {
    menuName: "Payments",
    read_only: ["payments"],
    edit: []
  },
  {
    menuName: "Orders",
    read_only: ["orders"],
    edit: []
  },
  {
    menuName: "Reports",
    read_only: ["reports"],
    edit: []
  },
  {
    menuName: "Students",
    read_only: ["students"],
    edit: []
  },
  {
    menuName: "Fair Inventory",
    read_only: ["fair-inventory"],
    edit: []
  },
  {
    menuName: "All Inventory",
    read_only: ["inventory"],
    edit: []
  },
]; 

export const PAYMENT_METHOD_TYPE = [
  { value: 1, label: 'Credit Card' },
  { value: 2, label: 'Check' },
  { value: 3, label: 'Cash' },
  { value: 4, label: 'Facts' },
  { value: 5, label: 'EWallet' },
  { value: 6, label: 'AveDollar' }
]

export const PARTIAL_PAYMENT = {
    FULL: '0',
    E_WALLET: '1',
    AVE_DOLLAR: '2'
}

export const REPORT_TYPE = [
  { value: "SALES_SUMMARY", label: 'Sales Summary' },
  { value: "ITEM_SALES", label: 'Item Sales' },
  { value: "DAILY_SALES", label: 'Daily Sales' },
  { value: "SHIP_TO_SCHOOL", label: 'Ship To School' },
  { value: "LAST_COPY", label: 'Last Copy' },
  { value: "SALES_ORDER_PER_ITEM", label: 'Order Per Item' },
  { value: "FAIR_RETURN_REPORT", label: 'Return Inventory' },
]

export const MONTHS_TO_SUBTRACT = 1;
export const TIME_UNIT_MONTH = 'month';
