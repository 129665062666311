import { Formik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { Card, Stack } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { loginEmailPassword } from '../../services/auth.service';
import InputPassword from "../../components/common/InputPassword";
import InputText from "../../components/common/InputText";
import { DASHBOARD_PATH, REMEMBER_ME, ROLES, VERIFY_ACCOUNT_PATH } from "../../constants";
import useCookie from "../../helpers/cookie";
import storage from "../../helpers/storage";
import { User, loader, login, logout, setPermission, setTitle } from "../../store/reducers/auth";
import { LoginForm, TokenInfo } from "../../types";
import { LoginSchema } from "./validation";
import leftuser from "../../assets/images/leftuser.png";
import { getDecodedToken } from "../../helpers";
import CustomButton from "../../components/common/button";
import { AxiosResponse } from "axios";
import ability, { buildAbilities, buildAbilitiesForSuperAdmin } from "../../role-manager/ability";

/**
 * Form values type definition.
 */
type FormValues = {
    email: string;
    password: string;
};

const LoginComponent: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(true);

    const dispatch = useDispatch();
    const { getCookie, setCookieValue, removeCookie } = useCookie();
    const loginFormCookie: LoginForm | null = getCookie(REMEMBER_ME);

    /**
     * Initial Form Values
     */
    const initialValues: FormValues = {
        email: loginFormCookie?.email ?? "",
        password: loginFormCookie?.password ?? "",
    };

    /**
     * Hook to dispatch page title
     */
    useEffect(() => {
        dispatch(setTitle("Login"));
    }, []);

    useEffect(() => {
        if (storage.getToken() !== null) {
            const user: User = getDecodedToken() as User;
            if (!user?.email_verified) {
                storage.clearToken();
                dispatch(logout());
            } else {
                return navigate(DASHBOARD_PATH, { replace: true });
            }
        } else {
            storage.clearToken();
        }
    }, [navigate]);

    /**
     * Handles the change event of a checkbox input.
     * @param {ChangeEvent<HTMLInputElement>} event - The change event object.
     * @returns {void}
     */
    const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    /**
     * Submit Method to call when user clicks on login button
     * @async
     * @param {FormValues} values
     * @param {any} actions
     * @returns {*}
     */
    const submitForm = async (values: FormValues, { setSubmitting }: any) => {
        setLoading(true);
        setSubmitting(true);
        const formData: any = {
            email: values.email,
            password: values.password,
        };

        loginEmailPassword(formData).then((response: any) => {
            setSubmitting(false);
            if (isChecked) {
                setCookieValue(REMEMBER_ME, { email: values.email, password: values.password, isChecked });
            } else {
                removeCookie(REMEMBER_ME);
            }
            handleLoginResponse(response.data);
            toast.success(response.message);
        }).catch((error: AxiosResponse) => {
            console.log(error)
            setLoading(false);
            toast.error(error?.data?.message);
            setSubmitting(false);
        })
    };


    /**
     * Handles the response after a successful login.
     * @param {Object} responseData - The response data from the login API.
     * @returns {void}
     */
    const handleLoginResponse = (responseData: { token: TokenInfo, permission: any[]}) => {
        setLoading(false);
        dispatch(login(responseData.token));

        storage.setToken(responseData.token.access.token);
        storage.setRefreshToken(responseData.token.refresh.token);
        
        dispatch(setPermission(responseData.permission));
        const user = getDecodedToken() as User;
        if (!user?.email_verified) {
            navigate(VERIFY_ACCOUNT_PATH);
        } else {
            navigate(DASHBOARD_PATH, { replace: true });
        }
        if(user?.role?.role === ROLES.SUPER_ADMIN){
            ability.update(buildAbilitiesForSuperAdmin())
        }else{
            ability.update(buildAbilities(responseData.permission))
        }
    };

    return (
        <Row className="g-0 vh-md-100 position-relative z-1">
            <Col md className="h-100 overflow-auto">
                <Row className="justify-content-center g-0 align-items-center h-100 ">
                    <Col md={10} xxl={8} className="p-4 py-md-5">


                        <p className="text-secondary mb-0">Welcome to</p>
                        <h2 className="fw-semibold mb-3">Admin Panel</h2>
                        <Formik
                            validationSchema={LoginSchema}
                            initialValues={initialValues}
                            onSubmit={(values: FormValues, actions) => {
                                submitForm(values, actions);
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                            }) => (
                                <Form className="text-start" noValidate onSubmit={handleSubmit}>
                                    <InputText
                                        controlId="email"
                                        label="Email Address"
                                        placeholder="Email Address"
                                        touched={touched.email}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.email}
                                        value={values.email}
                                        setFieldValue={setFieldValue}
                                    />

                                    <InputPassword
                                        controlId="password"
                                        label="Password"
                                        placeholder="Password"
                                        touched={touched.password}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorsField={errors.password}
                                        value={values.password}
                                    />

                                    <Form.Group className="mb-3 pb-1">
                                        <Row xs="auto" className="justify-content-between gx-0">
                                            <Col>
                                                <Form.Check
                                                    id="rememberCheck"
                                                    checked={isChecked}
                                                    onChange={onChangeCheckbox}
                                                    className="fs-15"
                                                    type="checkbox"
                                                    label="Remember Me"
                                                />
                                            </Col>
                                            <Col>
                                                <Link
                                                    className="fs-14 fw-semibold text-info text-decoration-none"
                                                    to="/forgot-password"
                                                >
                                                    Forgot Password?
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Stack
                                        direction="horizontal"
                                        gap={3}
                                        className="justify-content-end"
                                    >
                                        <CustomButton
                                            variant="primary"
                                            type="submit"
                                            loading={isSubmitting}
                                        >
                                            Login
                                        </CustomButton>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Col>
            <Col
                md={7}
                xl={7}
                className="col-md-auto h-100 start-0 top-0 z-n1 order-md-first"
            >
                <Card className="h-100 text-white border-0">
                    <img
                        className="h-100vh object-fit-cover w-100"
                        src={leftuser}
                        alt="Login Banner"
                    />
                    <Card.ImgOverlay className="auth-bg-gradient"></Card.ImgOverlay>
                    <Card.ImgOverlay className="d-flex align-items-center justify-content-center display-5 fw-medium font-family-secondary">

                    </Card.ImgOverlay>
                </Card>
            </Col>
        </Row>
    );
}
export default LoginComponent;